import { combineReducers } from "redux"

import menu from "./menu"
import dashboard from "./dashboard"

export default combineReducers( {

    menu,
    dashboard

} )
